<template>
  <fragment>
    <form @submit.prevent>
      <div class="modal-header">
        <h5 class="modal-title">I'm Interested</h5>
      </div>

      <div class="modal-body">
        <p>To add your company to the bidders list, please provide the following information:</p>

        <div class="form-group">
          <label for="bidderType">Bidder Type <span class="red-dot ml-3"></span></label>
          <select class="custom-select" id="bidderType" name="bidderType" :class="{ 'is-invalid': errors.has('bidderType') }"
              v-validate="'required'" v-model="bidRequest.participantTypeId" data-vv-as="Bidder Type">
            <option v-for="participantType in getParticipantTypesBidRequestionOptions" :key="'bid-participant-' + participantType.id" :value="participantType.id">
              {{ participantType.name }}
            </option>
          </select>
          <div class="invalid-feedback">The Bidder Type field is required</div>
        </div>

        <div class="form-group">
          <span> What contact information will be displayed: </span>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="contactDisplay" id="contactDisplayMyself" :value="ContactDisplayEnum.MYSELF" checked
              v-model="bidRequest.contactDisplay">
            <label class="form-check-label" for="contactDisplayMyself">
              Display my <strong> contact </strong> information, including my email
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="contactDisplay" id="contactDisplayCompany" :value="ContactDisplayEnum.COMPANY"
              v-model="bidRequest.contactDisplay">
            <label class="form-check-label" for="contactDisplayCompany">
              Display my <strong> company </strong> information, including the email
            </label>
          </div>
        </div>

        <div class="form-group">
          <span> Do you also want to "Follow" this project and receive email and dashboard updates? </span>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="addToFollowers" name="addToFollowers" v-model="bidRequest.addToFollowers" :value="true">
            <label class="form-check-label" for="addToFollowers">
              Yes, add me to the list of followers
            </label>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-success" @click="onSubmit">Save</button>
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
      </div>
    </form>
  </fragment>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Toast from "@/utils/toast";
import { ProjectBidService } from "@/services/";

const CONTACT_DISPLAY_ENUM = {
  MYSELF: 'MYSELF',
  COMPANY: 'COMPANY'
}

export default {
  name: "project-request-to-bid",
  data: function() {
    return {
      bidRequest: {
        projectId: this.$route.params.id,
        companyId: null,
        participantTypeId: null,
        contactDisplay: CONTACT_DISPLAY_ENUM.MYSELF,
        addToFollowers: true
      }
    };
  },
  computed: {
    ContactDisplayEnum: () => CONTACT_DISPLAY_ENUM,
    ...mapGetters(["getParticipantTypesBidRequestionOptions", "getCurrentUser"])
  },
  mounted: function() {
    Promise.all([
      this.fetchUser(this.getCurrentUser.id),
      this.fetchParticipantTypes()
    ])
    .then(result => {
      this.bidRequest.companyId = result[0].companies[0].id;
    })
    .catch(() => {
      let message = this.$createElement('span', {}, ['Oops! An error has occured and some data could not be loaded', this.$createElement('br'), 'Please contact an administrator']);
      Toast.danger(this, [message], { autoHideDelay: 7500 });
    });
  },
  methods: {
    async onSubmit () {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      try {
        var request = await ProjectBidService.requestToBid(this.bidRequest);
        this.saveProjectBid(request.data);
        Toast.success(this, 'Successfully added to bidder list!');
        this.$emit('user-is-bidding', { isBidding: true, isFollowing: this.bidRequest.addToFollowers });
        this.closeModal();
      }
      catch {
        Toast.danger(this, 'Oops! An error has occured')
      }
    },
    closeModal() {
      this.$bvModal.hide("bv-modal-request-to-bid");
    },
    ...mapActions(["saveProjectBid", "fetchCompany", "fetchParticipantTypes", "resetProjectBid", "fetchUser"])
  }
};
</script>
